// Libs
import React from 'react';
import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';

// Animations
const OpacityAnimation = keyframes`
  0% { opacity: .3; }
  100% { opacity: 1; }
`;

// Styles
const Container = styled.section`
  width: 100%;
  padding: 4rem 0;
  background: transparent linear-gradient(180deg, #FFFAE4 50%, #FFFFFF 80%) 0% 0%;
  
  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    animation: ${OpacityAnimation} 2s;
  `}
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 1440px;

  @media (max-width: 768px) {
    width: calc(100% - 80px);
  }
`;

const Image = styled.img`
  width: 100%;
`;

const FirstItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 23.5rem;
  margin-bottom: 3.5rem;
  padding: 1.25rem;
  border: 2px solid transparent;
  border-radius: .625rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 6px #00000015;
  cursor: pointer;

  &:hover {
    border-color: #5321C4;

    ${Image} {
      transform: scale(1.1);
      transition: .3s linear;
    }
  }

  @media (max-width: 1024px) {
    height: 16.75rem;
    padding: .75rem;
  }

  @media (max-width: 768px) {
    height: 14.25rem;
  }
`;

const FirstImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 1rem);
  height: 100%;
  border: 1px solid #CAAAE5;
  border-radius: 5px;
  overflow: hidden;
`;

const FirstTitle = styled.h4`
  margin: 0;
  font: 600 2.8125rem 'Soleto', sans-serif;
  line-height: 3.5rem;

  @media (max-width: 1024px) {
    font-size: 2.25rem;
  }

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
`;

const FirstIntroduction = styled.p`
  display: -webkit-box;
  height: 4.5rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
  margin-top: 1rem;
  font: 400 1.0625rem 'Soleto', sans-serif;
  line-height: 1.5rem;

  @media (max-width: 1024px) {
    height: 6rem;
    -webkit-line-clamp: 4;
    font-size: .9375rem;
  }

  @media (max-width: 768px) {
    height: 4rem;
    -webkit-line-clamp: 3;
    font-size: .9375rem;
  }
`;

const PostList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: .875rem;
  grid-row-gap: 3.5rem;
  grid-auto-rows: minmax(100px, auto);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  list-style: none;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    display: block;
  }
`;

const PostItem = styled.li`
  width: 100%;
  border-radius: .625rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 6px #00000015;
  border: 2px solid transparent;
  cursor: pointer;

  @media (min-width: 769px) {
    ${({ isLeft }) => isLeft && `
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
    `}

    ${({ isRight }) => isRight && `
      grid-column-start: 3;
      grid-column-end: 5;
      grid-row-start: 1;
    `}
  }

  &:hover {
    border-color: #5321C4;

    ${Image} {
      transform: scale(1.1);
      transition: .3s linear;
    }
  }

  @media (max-width: 480px) {
    margin-bottom: 1.5rem;
  }
`;

const ContainerPost = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: ${props => props.isBigger ? '29.0625rem' : '25.3125rem'};
  padding: 1.25rem;

  @media (max-width: 1024px) {
    min-height: ${props => props.isBigger ? '29.0625rem' : '23.3125rem'};
    padding: .75rem;
  }

  @media (max-width: 480px) {
    min-height: 20rem;
  }
`;

const PostBox = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => props.isBigger ? '14.5625rem' : '9.4375rem'};
  border: 1px solid #CAAAE5;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: ${props => props.isBigger ? '12.5625rem' : '6.4375rem'};
  }

  @media (max-width: 768px) {
    height: 11rem;
  }

  @media (max-width: 480px) {
    height: 8.5625rem;
  }
`;

const Title = styled.h4`
  margin: 0;
  margin-top: 1rem;
  font: 600 ${props => props.isBigger ? '1.75rem' : '1.375rem'} 'Soleto', sans-serif;
  line-height: ${props => props.isBigger ? '2.1875rem' : '1.875rem'};

  @media (max-width: 1024px) {
    font-size: ${props => props.isBigger ? '1.5rem' : '1rem'};
    line-height: ${props => props.isBigger ? '1.8875rem' : '1.5rem'};
  }

  @media (max-width: 768px) {
    font-size: 1.6875rem;
    line-height: 2.0625rem;
  }
`;

const Introduction = styled.p`
  display: -webkit-box;
  height: 4.75rem;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
  margin-top: 1rem;
  font: 400 ${props => props.isBigger ? '.875rem' : '.8125rem'} 'Soleto', sans-serif;
  line-height: 1.25rem;

  @media (max-width: 1024px) {
    font-size: ${props => props.isBigger ? '.825rem' : '.75rem'};
    line-height: 1.125rem;
  }

  @media (max-width: 768px) {
    height: 4.5rem;
    -webkit-line-clamp: 4;
  }

  @media (max-width: 768px) {
    font-size: .75rem;
  }
`;

const PostDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(50% - 1rem);
`;

const AuthorBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const PictureBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 20px;
  margin-right: .46875rem;
  overflow: hidden;
`;

const Picture = styled.img`
  width: 100%;
`;

const DetailsBox = styled.div``;

const AuthorName = styled.p`
  margin: 0;
  font: 400 .9375rem 'Soleto', sans-serif;
  color: #5321C4;
  line-height: 1.375rem;

  @media (max-width: 1024px) {
    font-size: .6875rem;
    line-height: 1rem;
  }
`;

const PostDate = styled.p`
  margin: 0;
  font: 300 .9375rem 'Soleto', sans-serif;
  color: #22254B;
  line-height: 1.375rem;

  @media (max-width: 1024px) {
    font-size: .675rem;
  }

  @media (max-width: 768px) {
    font-size: .6875rem;
    line-height: 1rem;
  }
`;

const BlogList = ({
  language,
  postList,
  isMobile
}) => {
  const renderFirstItem = () => {
    const dateSplit = postList[0].date.split('-');
    const date = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);

    const lang = language === 'pt-BR' ? language : 'en-US';

    const postDate = date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' });

    return (
      <Link
        to={`/blog/${postList[0].slug}`}
        style={{ display: 'block', width: '100%', textDecoration: 'none', color: 'inherit' }}
      >
        <FirstItem>
          <FirstImageContainer>
            <Image
              src={postList[0].coverImage?.url}
              alt='Publication cover'
            />
          </FirstImageContainer>
          <PostDetails>
            <PostBox>
              <FirstTitle>
                {postList[0].title}
              </FirstTitle>
              <FirstIntroduction>
                {postList[0].intro}
              </FirstIntroduction>
            </PostBox>
            <AuthorBox>
              <PictureBox>
                <Picture
                  src={postList[0].authorPicture?.url}
                  alt='Author picture'
                />
              </PictureBox>
              <DetailsBox>
                <AuthorName>{postList[0].author}</AuthorName>
                <PostDate>{postDate}</PostDate>
              </DetailsBox>
            </AuthorBox>
          </PostDetails>
        </FirstItem>
      </Link>
    );
  }

  const renderPosts = () => (
    <PostList>
      {postList.map((post, index) => {
        if (!isMobile && index === 0) return null;

        const dateSplit = post.date.split('-');
        const date = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);

        const lang = language === 'pt-BR' ? language : 'en-US';

        const postDate = date.toLocaleDateString(lang, { day: 'numeric', month: 'long', year: 'numeric' });

        const isLeft = index === 1;
        const isRight = index === 2;
        const isBigger = index === 1 || index === 2;

        return (
          <PostItem
            key={post.id}
            isLeft={isLeft}
            isRight={isRight}
          >
            <Link
              to={`/blog/${post.slug}`}
              style={{ display: 'block', width: '100%', height: '100%', textDecoration: 'none', color: 'inherit' }}
            >
              <ContainerPost isBigger={isBigger}>
                <PostBox>
                  <ImageContainer isBigger={isBigger}>
                    <Image
                      src={post.coverImage?.url}
                      alt='Publication cover'
                    />
                  </ImageContainer>
                  <Title
                    isBigger={isBigger}
                  >
                    {post.title}
                  </Title>
                  <Introduction
                    isBigger={isBigger}
                  >
                    {post.intro}
                  </Introduction>
                </PostBox>
                <AuthorBox>
                  <PictureBox>
                    <Picture
                      src={post.authorPicture?.url}
                      alt='Author picture'
                    />
                  </PictureBox>
                  <DetailsBox>
                    <AuthorName>{post.author}</AuthorName>
                    <PostDate>{postDate}</PostDate>
                  </DetailsBox>
                </AuthorBox>
              </ContainerPost>
            </Link>
          </PostItem>
        );
      })}
    </PostList>
  );

  return (
    <Container>
      <Wrapper>
        {!isMobile && renderFirstItem()}
        {renderPosts()}
      </Wrapper>
    </Container>
  );
};

export default BlogList;